import React, { Component } from "react"
import waveImagingLogo from "../images/logo-Waveimaging.png"
import dynamicMedLogo from "../images/dynamic-medical-imaging-logo.png"
import waveImagingMap from "../images/waveimaging-map-banner.jpg"
//import cache from '../../cache'
//import locations from "../data/locations"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ListItemDynamicMed from "../components/locationItemDynamicMed"
//import Form from "../components/form"

class DynamicMedPage extends Component {
  constructor(props){
    super(props)
    this.state = {
      locations: []
    }
  }
  componentDidMount(){
      //change to data from RadNet (update)
      //get locations from OC & LB

      //if fresh data, use this ->

      //fetch(`https://stage.radnet.com/locator/search/json?locator_search=CA`)
      fetch(`https://www.radnet.com/locator/search/json?locator_search=CA`)
        .then(res => res.json())
        .then(res => {
          let data = res
            //nid	:	3016
            //nid	:	3011
            //nid	:	3021
          /*let sbLocations = data.matched.filter(item => item.did === "2081")
          let ocLocations = data.matched.filter(item => item.did === "28")
          let lbLocations = data.matched.filter(item => item.did === "22" && item.nid !== "871")*/
          let dynMedLocations = data.matched.filter(item => item.nid === "3016" || item.nid === "3011" || item.nid === "3021")
          let locations = [...dynMedLocations]
          locations = locations.sort((a,b)=>{
              if(a.title > b.title){
                  return 1
              }
              if(a.title < b.title){
                  return -1
              }
              return 0
          })
          this.setState({locations: locations})
        })
        .catch(err => console.log(err))
  }
  render() {
    let {locations} = this.state
    console.log(locations)

    return (
      <Layout>
        <SEO title="WaveImaging | Formerly Dynamic Med" />
        <section id="splash" className="container">
          <div className={`wave-info centered mb-5`}>
            <img
              src={waveImagingLogo}
              alt="Wave Imaging Logo"
              style={{ width: `100%`, maxWidth: `600px` }}
            />
            <div className={`dynamic-container`}>
                <h2>Formerly</h2> 
                <img
                    src={dynamicMedLogo}
                    alt="Wave Imaging Logo"
                    />
                </div>
            </div>  
            <div className="row-dynamic">
                <div>
                <ul>
                    {locations.slice(0, locations.length / 2).map((item, idx) => {
                    return <ListItemDynamicMed item={item} key={idx} show={true}/>
                    })}
                    {locations
                    .slice(locations.length / 2, locations.length)
                    .map((item, idx) => {
                        return <ListItemDynamicMed item={item} key={idx} />
                    })}
                </ul>
                </div>
            </div>
          <div className="bottom-dynamic">
            <h2>PHYSICIAN PORTAL</h2>
            <div>
                <a className="btn-portal" href="https://portal.socaldiagnostics.com/novaweb/Lite/Login.aspx" target="_self">
                    <p>For Images/Reports</p>
                    <p className="sp">BEFORE</p>
                    <h3>January 5, 2023</h3>
                </a>
                <a className="btn-portal" href="https://myradiologyconnectportal.com/ImagingCenter/ROCPP" target="_self">
                    <p>For Images/Reports</p>
                    <p className="sp">ON/AFTER</p>
                    <h3>January 5, 2023</h3>
                </a>
            </div>
            <div>
                <a className="btn-network" href="/" target="_self">View Entire WaveImaging Network</a>
            </div>
            
          </div>
        </section>
      </Layout>
    )
  }
}

export default DynamicMedPage
